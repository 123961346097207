export default {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
};
